export interface IProgramChildSearchQuery {
    programCode?: string;
    name?: string;
    address?: string;
    classroom? : string;
    childCode?: string;
    created?: string;
};

export interface IProgramClassroomChildSearchQuery {
  programCode?: string;
  programName?: string;
  programAddress?: string;
  classroom? : string; // Name or StateId
  childCode?: string;
};