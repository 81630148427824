// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from "./environment.interface";

const API_BASE_URL = 'https://dev.coaching-vaitsnetwork.org/api/';

export const environment: IEnvironment = {
  production: false,
  apiBaseURL: API_BASE_URL,
  azureADAuthProvider: {
    clientId: "aef43610-37f9-47ae-a778-33a7d50d06eb",
    tenantId: "7203a756-9f21-4bea-b8cb-17067c885a93"
  },
  googleClientId: "<google-client-id>",
  version: '0.3.10'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
