import { IEvent } from "./event.interface";
import { IProgram } from "./program.interface";
import { IVAUser } from "./va-user.interface";

export interface ITravelVoucherReport {
    id: number;
    vaUserId: string;
    username: string;
    homeAddress: string;
    homeRegionId: number;
    homeRegion: string;
    homeRegionCode: string;
    workOffice: string;
    workAddress: string;
    eventId: number;
    eventTypeId: number;
    eventType: string;
    travelRecordTraveledDateTime: string;
    typeId: number;
    travelType: string;
    from: string;
    fromTravelLocationTypeId: number;
    fromTravelLocationType: string;
    fromProgramId: number;
    fromProgram: string;
    to: string;
    toTravelLocationTypeId: number;
    toTravelLocationType: string;
    toProgramId: number;
    toProgram: string;
    mileage: number;
    tolls: number;
    comment: string;
    travelRecordDeleted: string;
    traveled: string;
    createdByUser: string;
    created: string;
    lastUpdatedByUser: string;
    lastUpdated: string;
    deleted: string;
}

export interface ITravelVoucher {
    id: number;
    vaUserId: string;
    eventId: number;
    traveled: string;
    createdByUser: string;
    created: string;
    lastUpdatedByUser: string;
    lastUpdated: string;
    deleted: string;
    vaUser?: IVAUser;
}

export interface ITravelRecord {
    id: number;
    eventId: number;
    travelVoucherId: number;
    traveled: string;
    alpha: string;
    typeId: number;
    from: string;
    fromTravelLocationTypeId: number;
    fromProgramId: number;
    to: string;
    toTravelLocationTypeId: number;
    toProgramId: number;
    mileage: number;
    tolls: number;
    comment: string;
    deleted: string;

    event?: IEvent;
    travelVoucher?: ITravelVoucher;
    fromProgram?: IProgram;
    toProgram?: IProgram;
}

export class TravelRecord {
    id: number;
    travelVoucherId: number;
    eventId: number;
    traveled: Date;
    typeId: number;
    locationTypeId: number;
    program: IProgram;
    address: string;
    mileage: number;
    tolls: number;
    comment: string;

    constructor(traveled: Date = null) {
        this.travelVoucherId = 0;
        this.eventId = null;
        this.traveled = traveled ? new Date(traveled) : null;
        this.typeId = null;
        this.locationTypeId = null;
        this.program = null;
        this.address = null;
        this.mileage = null;
        this.tolls = null;
        this.comment = null;
    }
}

export interface ITravelVoucherRequest {
    travelVoucher: ITravelVoucher;
    travelRecords: Array<ITravelRecord>;
}

export interface ITravelType {
    Id: number;
    Name: string;
}

export interface ITravelLocationType {
    id: number;
    name: string;
}

export interface ITravelLocation {
    id: number;
    vaUserId: string;
    regionId: number;
    address: string;
    name: string;
    typeId: number;
}