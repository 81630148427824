import { Injectable } from "@angular/core";

export class ChildSpecificScale {
  VAUserId: string;
  ReferralName: string;
  ChildId: number;
  AgeMonth: number;
  IsHealthPremature: boolean;
  IsHealthPreMeds: boolean;
  IsHealthEats: boolean;
  IsHealthPoorSleep: boolean;
  IsHealthAllergic: boolean;
  IsHealthPoorAppetite: boolean;
  IsHealthMouthBreaths: boolean;
  IsDevelopBehind: boolean;
  IsDevelopChildReferred: boolean;
  IsDevelopAbovePeers: boolean;
  IsDevelopStiff: boolean;
  IsDevelopSensoryLoss: boolean;
  IsDevelopNoImitation: boolean;
  IsDevelopNoGestures: boolean;
  IsDevelopKnowHouseholdObj: boolean;
  IsDevelopNoToyPlay: boolean;
  IsDevelopRegression: boolean;
  IsSocialEmotionalDelayed: boolean;
  IsSocialEmotionalNotExpressional: boolean;
  IsSocialEmotionalSingleAttachment: boolean;
  IsSocialEmotionalNoInitiatePlay: boolean;
  IsSocialEmotionalRigid: boolean;
  IsSocialEmotionalNoDisplayNeeds: boolean;
  IsSocialEmotionalNoGestures: boolean;
  IsSocialEmotionalNoSmile: boolean;
  IsBehaviorConcernChildSafety: boolean;
  IsBehaviorTooPassive: boolean;
  IsBehaviorLongTantrums: boolean;
  IsBehaviorConstantlyInMotion: boolean;
  IsBehaviorSitsClose: boolean;
  IsBehaviorChildAbsent: boolean;
  IsBehaviorAggressive: boolean;
  IsBehaviorSelfInjury: boolean;
  IsBehaviorSensitive: boolean;
  IsEngagementWithdrawn: boolean;
  IsEngagementDiffTurnTaking: boolean;
  IsEngagementDiffInterest: boolean;
  IsEngagementOtherTeacher: boolean;
  IsEngagementChallenging: boolean;
  IsEngagementDiffAcknowledge: boolean;
  IsEngagementBoundaries: boolean;
  IsTraumaHouseholdChange: boolean;
  IsTraumaCPS: boolean;
  IsTraumaCustodial: boolean;
  IsTraumaFamily: boolean;
  IsTraumaHistory: boolean;
  IsEnvironmentalBilingual: boolean;
  IsEnvironmentalConcerns: boolean;
  IsEnvironmentalLacksTransportation: boolean;
  IsEnvironmentalExpNaturalDisaster: boolean;
  IsEnvironmentalHouseInstability: boolean;
  IsEnvironmentalChildCare: boolean;
  IsEnvironmentalIsolated: boolean;
  Created: string;
  CreatedByUser: string;
  LastUpdatedUser: string;

  constructor() {
    this.VAUserId = null;
    this.ReferralName = null;
    this.ChildId = null;
    this.AgeMonth = null;
    this.IsHealthPremature = null;
    this.IsHealthPreMeds = null;
    this.IsHealthEats = null;
    this.IsHealthPoorSleep = null;
    this.IsHealthAllergic = null;
    this.IsHealthPoorAppetite = null;
    this.IsHealthMouthBreaths = null;
    this.IsDevelopBehind = null;
    this.IsDevelopChildReferred = null;
    this.IsDevelopAbovePeers = null;
    this.IsDevelopStiff = null;
    this.IsDevelopSensoryLoss = null;
    this.IsDevelopNoImitation = null;
    this.IsDevelopNoGestures = null;
    this.IsDevelopKnowHouseholdObj = null;
    this.IsDevelopNoToyPlay = null;
    this.IsDevelopRegression = null;
    this.IsSocialEmotionalDelayed = null;
    this.IsSocialEmotionalNotExpressional = null;
    this.IsSocialEmotionalSingleAttachment = null;
    this.IsSocialEmotionalNoInitiatePlay = null;
    this.IsSocialEmotionalRigid = null;
    this.IsSocialEmotionalNoDisplayNeeds = null;
    this.IsSocialEmotionalNoGestures = null;
    this.IsSocialEmotionalNoSmile = null;
    this.IsBehaviorConcernChildSafety = null;
    this.IsBehaviorTooPassive = null;
    this.IsBehaviorLongTantrums = null;
    this.IsBehaviorConstantlyInMotion = null;
    this.IsBehaviorSitsClose = null;
    this.IsBehaviorChildAbsent = null;
    this.IsBehaviorAggressive = null;
    this.IsBehaviorSelfInjury = null;
    this.IsBehaviorSensitive = null;
    this.IsEngagementWithdrawn = null;
    this.IsEngagementDiffTurnTaking = null;
    this.IsEngagementDiffInterest = null;
    this.IsEngagementOtherTeacher = null;
    this.IsEngagementChallenging = null;
    this.IsEngagementDiffAcknowledge = null;
    this.IsEngagementBoundaries = null;
    this.IsTraumaHouseholdChange = null;
    this.IsTraumaCPS = null;
    this.IsTraumaCustodial = null;
    this.IsTraumaFamily = null;
    this.IsTraumaHistory = null;
    this.IsEnvironmentalBilingual = null;
    this.IsEnvironmentalConcerns = null;
    this.IsEnvironmentalLacksTransportation = null;
    this.IsEnvironmentalExpNaturalDisaster = null;
    this.IsEnvironmentalHouseInstability = null;
    this.IsEnvironmentalChildCare = null;
    this.IsEnvironmentalIsolated = null;
    this.Created = new Date().toLocaleDateString();
    this.CreatedByUser = null;
    this.LastUpdatedUser = null;
  }
}