import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import ODataFilterBuilder from 'odata-filter-builder';

import {
  IChild,
  IClassroom,
  IParamObj,
  IProgram,
  IProgramClassroomChildSearchQuery
} from '../../models';
import { ChildService } from '../../services/child.service';

/** 
 * Odata query of Child table with associated Program,Classroom&Lookup expanded 
 * instead of querying LookupAssociatedClassProgramChild table
 * @todo eventually should remove lookup logic altogether
 * */
@Component({
  selector: 'app-search-program-classroom-child-odata',
  templateUrl: './search-program-classroom-child-odata.component.html',
  styleUrls: ['./search-program-classroom-child-odata.component.scss']
})
export class SearchProgramClassroomChildODataComponent implements OnInit {
  @Input() currentProgram: IProgram = null;
  @Input() currentClassroom: IClassroom = null;
  @Input() currentChild: IChild = null;
  @Output() select$ = new EventEmitter<IChild>();

  public isNotSearching: boolean = true;
  public searchResults$: Observable<Array<IChild>>;
  private searchText$ = new Subject<string>();
  private oDataFilter = ODataFilterBuilder();
  public searchQuery: IProgramClassroomChildSearchQuery;

  constructor(
    private childService: ChildService
  ) { }

  ngOnInit() {
    this.getSearchResults();
  }

  onSelect(selectedSearchResult: IChild) {
    this.currentProgram = selectedSearchResult.program;
    this.currentClassroom = selectedSearchResult.classroom;
    this.currentChild = selectedSearchResult;
    this.isNotSearching = true;
    this.select$.emit(selectedSearchResult);
  }

  public getEventValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  public updateSearch(searchString: string) {
    this.buildFilter();
    this.searchText$.next(searchString);
  }

  public initSearchFilters() {
    this.isNotSearching = false;
    this.searchQuery = {
      programCode: '',
      programName: '',
      programAddress: '',
      classroom: '',
      childCode: '',
    };
  }

  /** Dynamic filter by search query */
  private buildFilter() {
    this.oDataFilter = ODataFilterBuilder("and")
      .contains('code', this.searchQuery.childCode)
      .contains('Program/code', this.searchQuery.programCode)
      .contains('Program/name', this.searchQuery.programName)
      .contains('Program/address', this.searchQuery.programAddress)
      .and(ODataFilterBuilder('or')
        .contains('Classroom/name', this.searchQuery.classroom)
        .contains('Classroom/stateId', this.searchQuery.classroom)
      );
  }

  /**
   * Get search results from odata Child entity w/Program+Class expanded
   * Filter based on provided search parameters
   * Return top 8 results,
   * ordered by Program.Code ASC, then by Classroom.StateId ASC, then by Child.Code ASC
   */
  private getSearchResults() {
    this.searchResults$ = this.searchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(() => {
        const params = { 
          $expand: 'Program($select=id,code,name,address),Classroom($select=id,name,stateId)',
          $filter: this.oDataFilter.toString(),
          $orderby: 'Program/code asc, Classroom/stateId asc, Code asc',
          $top: 8 
        } as IParamObj;
        
        return this.childService.get(params);
      })
    );
  }
}
