<div *ngIf="isFormLoading">
    <div class="loader">
        <mat-spinner></mat-spinner>
    </div>
</div>
<div *ngIf="!isFormLoading" class="form-container">
    <h1 class="form-h1">Travel Voucher</h1>
    <div id="coach" *ngIf="(vaUserService.hasRole(VAUserRole.Admin) | async)" class="vaitsn-form-grid-cell">
        <mat-label>Coach*</mat-label>
        <div class="vaitsn-form-control">
            <mat-form-field appearance="outline" class="vaitsn-text-field">
                <input type="text" matInput [(ngModel)]="selectedVaUser" (keyup)="vaUserSearch(getEventValue($event))"
                    [matAutocomplete]="vaUserAuto" placeholder="Search by Name or Username"
                    [disabled]="!isEditableOnPatch(false)" required>
                <mat-autocomplete #vaUserAuto="matAutocomplete" [displayWith]="vaUserDisplay"
                    (optionSelected)="onVAUserChanged($event)">
                    <mat-option *ngFor="let user of vaUsers$ | async" [value]="user">
                        {{vaUserDisplay(user)}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error>Coach is required.</mat-error>
            </mat-form-field>
        </div>
    </div>
    <form [formGroup]="travelVoucherForm" (ngSubmit)="onSubmit()">
        <div id="voucherTraveled" class="vaitsn-form-grid-cell">
            <mat-label>Traveled Date*</mat-label>
            <div class="vaitsn-form-control">
                <mat-form-field appearance="outline" class="vaitsn-date">
                    <input matInput [matDatepicker]="traveled" formControlName="traveled" [min]="minDate"
                        [max]="maxDate" placeholder="MM/DD/YYYY" (dateChange)="dateTraveledChanged($event)">
                    <mat-datepicker-toggle class="vaitsn-date-button" matIconSuffix [for]="traveled"><mat-icon
                            class="vaitsn-date-icon" matDatepickerToggleIcon><svg xmlns="http://www.w3.org/2000/svg"
                                width="11px" height="12px" fill="none">
                                <path
                                    d="M9.9 1.09091H9.35V0H8.25V1.09091H2.75V0H1.65V1.09091H1.1C0.495 1.09091 0 1.58182 0 2.18182V10.9091C0 11.5091 0.495 12 1.1 12H9.9C10.505 12 11 11.5091 11 10.9091V2.18182C11 1.58182 10.505 1.09091 9.9 1.09091ZM9.9 10.9091H1.1V3.81818H9.9V10.9091Z"
                                    fill="#195DC3" />
                            </svg></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #traveled></mat-datepicker>
                    <mat-error>Traveled Date is required.</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div id="voucherEvent" class="vaitsn-form-grid-cell">
            <mat-label>Event</mat-label>
            <div class="vaitsn-form-control">
                <mat-form-field appearance="outline" class="vaitsn-text-field">
                    <mat-select formControlName="event" class="vaitsn-select-box" required
                        (selectionChange)="eventChanged($event)" placeholder="Event*">
                        <mat-option *ngFor="let event of events" [value]="event">
                            {{eventDisplay(event)}}
                        </mat-option>
                    </mat-select>
                    <mat-error>Event is required. Select an Event ID or <i>Travel Only</i>.</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div id="voucherIsRoundTrip" class="roundtrip-row">
            <div class="vaitsn-form-control">
                <mat-checkbox formControlName="isRoundTrip" (change)="onRoundTripChanged($event)">
                    Roundtrip?
                </mat-checkbox>
            </div>
        </div>
    </form>
    <div class="travel-records-container">
        <div *ngFor="let record of travelVoucherRecords; let i = index" class="travel-records-grid tv-row">
            <div class="outliner-container-grid">
                <div>
                    <div class="address-type-container">
                        <span *ngIf="i == 0">From</span>
                        <span *ngIf="i > 0">To</span>
                    </div>
                </div>
                <div class="remove-record-col">
                    <a *ngIf="i > 0 && isEditableOnPatch(false)">
                        <mat-icon class="cancel-icon" aria-hidden="false" (click)="removeRecord(i)" aria-label="cancel"
                            fontIcon="cancel">
                        </mat-icon>
                    </a>
                </div>
            </div>
            <div class="inner-grid">
                <ng-container *ngIf="i == 0; else to">
                    <div id="trFromLocationTypeId" class="travel-record-full-row">
                        <div class="tv-col">
                            <mat-form-field appearance="outline" class="vaitsn-text-field travel-record-input">
                                <mat-select [(ngModel)]="record.locationTypeId" required
                                    (selectionChange)="locationTypeChanged($event, i)" placeholder="Location*"
                                    class="vaitsn-select-box">
                                    <mat-option *ngFor="let type of travelLocationTypes" [value]="type.id">
                                        {{type.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>From Location Type is required.</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
                <ng-template #to class="travel-record-row">
                    <div id="trToTraveled" class="tv-col">
                        <dx-date-box type="time" [(ngModel)]="record.traveled" placeholder="Time*"
                            [showClearButton]="false" [useMaskBehavior]="true"
                            [inputAttr]="{ 'aria-label': 'Date Time' }" [min]="getMinTime(i)" [max]="getMaxTime(i)">
                        </dx-date-box>
                        <mat-error *ngIf="i > 0 && record.traveled == null">To Location Type is required.</mat-error>
                    </div>
                    <div id="trToLocationTypeId" class="tv-col">
                        <mat-form-field appearance="outline" class="vaitsn-text-field travel-record-input">
                            <mat-select [(ngModel)]="record.locationTypeId" required
                                (selectionChange)="locationTypeChanged($event, i)" placeholder="Location*"
                                class="vaitsn-select-box">
                                <mat-option *ngFor="let type of travelLocationTypes" [value]="type.id">
                                    {{type.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error>To Location Type is required.</mat-error>
                        </mat-form-field>
                    </div>
                </ng-template>
                <div class="travel-record-full-row">
                    <div id="trProgram" *ngIf="record.locationTypeId === 3" class="tv-col">
                        <app-search-program-odata [currentProgram]="record.program"
                            (select$)="selectedProgram($event, i)">
                        </app-search-program-odata>
                        <mat-error *ngIf="!record.program" class="mat-error-outside-form-field">
                            Program is required.
                        </mat-error>
                    </div>
                    <div id="trAddress" *ngIf="record.locationTypeId !== 3" class="tv-col">
                        <mat-form-field appearance="outline" class="vaitsn-text-field travel-record-input">
                            <input type="text" matInput [(ngModel)]="record.address" [placeholder]="record.locationTypeId === 4 ? 'Address*' : 'Address'"
                                [disabled]="record.locationTypeId !== 4" required>
                            <mat-error>Address is required.</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <ng-container *ngIf="i > 0">
                    <div class="travel-record-row">
                        <div id="trMileage" class="tv-col">
                            <mat-form-field appearance="outline" class="vaitsn-text-field travel-record-input">
                                <input type="number" matInput [(ngModel)]="record.mileage" placeholder="Mileage* (0.0)"
                                    step="0.1" min="0.1" [required]="i > 0">
                                <mat-error>Mileage must be greater than 0.</mat-error>
                            </mat-form-field>
                            <mat-error *ngIf="isInvalidDecimalFormat(record.mileage, 1)">
                                Enter valid mileage (15 digits max; 0-1 digits after the decimal point).
                            </mat-error>
                        </div>
                        <div id="trTolls" class="tv-col">
                            <mat-form-field appearance="outline" class="vaitsn-text-field travel-record-input">
                                <input type="number" matInput [(ngModel)]="record.tolls" min="0.1"
                                    placeholder="Tolls (0.00)">
                                <mat-error>Toll amount must be greater than 0.</mat-error>
                            </mat-form-field>
                            <mat-error *ngIf="isInvalidDecimalFormat(record.tolls, 2)">
                                Enter valid toll amount (15 digits max; 0-2 digits after the decimal point).
                            </mat-error>
                        </div>
                    </div>
                    <div *ngIf="i > 0" class="travel-record-full-row">
                        <div id="trAddress" class="tv-col">
                            <mat-form-field appearance="outline" class="vaitsn-text-field travel-record-input">
                                <input type="text" matInput [(ngModel)]="record.comment" placeholder="Comment">
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="insert-row-container">
        <vaitsn-button color="primary" [hasLoading]="false" size="square" customWidth="100%" customHeight="40px"
            class="vaitsn-btn-primary" (click)="insertRecord()"
            [disabled]="isAddStopDisabled() || !isEditableOnPatch(false)">Add
            Stop</vaitsn-button>
    </div>
    <br />

    <div *ngIf="currentTravelVoucherId === 0; else updateButton" class="vaitsn-form-submit-container-size">
        <vaitsn-button
            [disabled]="((vaUserService.hasRole(VAUserRole.Admin) && !selectedVaUser) || !validDataToSubmit() || !travelVoucherForm.valid)"
            size="custom" customWidth="100%" customHeight="30px" color="primary" mat-flat-button (click)="onSubmit()"
            role="submit">Submit</vaitsn-button>
    </div>


    <ng-template #updateButton>
        <div class="vaitsn-form-submit-container-size">
            <vaitsn-button [disabled]="!validDataToSubmit()" size="custom" customWidth="100%" customHeight="30px"
                color="primary" class="vaitsn-btn-primary" mat-flat-button (click)="onUpdate()"
                role="submit">Update</vaitsn-button>
        </div>
    </ng-template>

</div>