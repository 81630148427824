import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Subject,
  filter,
  map,
  takeUntil,
  tap
} from 'rxjs';

import { 
  ChildSpecificScale,
  IChild,
  IVAUser
} from '../../models';
import { ChildSpecificScaleService } from '../../services/child-specific-scale.service';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';
import { VAUserRole, VAUserService, VAUserPermission } from '../../services/va-user.service';

@Component({
  selector: 'app-child-specific-scale',
  templateUrl: './child-specific-scale.component.html',
  styleUrls: ['./child-specific-scale.component.scss']
})

export class ChildSpecificScaleComponent implements OnInit, OnDestroy {
  VAUserRole = VAUserRole;
  VAUserPermission = VAUserPermission;
  private _subscribedSubjects$ = new Subject<boolean>();
  currentVAUser: IVAUser = null;
  public ChildSpecificScaleForm: FormGroup = null;
  public ChildSpecificScaleSubmitForm: ChildSpecificScale = null;

  HealthQuestions: Array<any> = [];
  DevelopmentQuestions: Array<any> = [];
  SocialEmotionalQuestions: Array<any> = [];
  BehaviorQuestions: Array<any> = [];
  EngagementQuestions: Array<any> = [];
  TraumaQuestions: Array<any> = [];
  EnvironmentalCulturalQuestions: Array<any> = [];

  constructor(
    public vaUserService: VAUserService,
    private childSpecificScaleService: ChildSpecificScaleService,
    private router: Router,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getVAUserDetail();

    this.getHealthQuestions();
    this.getDevelopmentQuestions();
    this.getSocialEmotionalQuestions();
    this.getBehaviorQuestions();
    this.getEngagementQuestions();
    this.getTraumaQuestions();
    this.getEnvironmentalCulturalQuestions();
  }

  getVAUserDetail() {
    this.vaUserService.currentAssociatedVAUser$.pipe(
      filter(c => c !== null),
      takeUntil(this._subscribedSubjects$),
      map((vaUser) => {
        this.currentVAUser = vaUser;
        if (!this.vaUserService.userHasRole(vaUser, VAUserRole.Admin)) {
          this.ChildSpecificScaleForm.patchValue({ User: this.currentVAUser.firstName + ' ' + this.currentVAUser.lastName })
          this.ChildSpecificScaleForm.patchValue({ ObserverFirstName: this.currentVAUser.firstName });
          this.ChildSpecificScaleForm.patchValue({ ObserverLastName: this.currentVAUser.lastName });
        }
      })
    ).subscribe();
  }

  public selectedChild(value: IChild) {
    this.ChildSpecificScaleForm.patchValue({
      ChildId: value.id,
    });
  }

  public selectedVAUser(value: IVAUser) {
    this.currentVAUser = value;
    this.ChildSpecificScaleForm.patchValue({ User: this.currentVAUser.firstName + ' ' + this.currentVAUser.lastName })
  }

  private getHealthQuestions() {
    this.HealthQuestions = [
      { HealthQuestionID: 1, HealthQuestion: 'Premature/traumatic birth experience/low birth weight/extended hospital stay', TF: false },
      { HealthQuestionID: 2, HealthQuestion: 'On prescribed medication/has medical diagnosis/had surgery', TF: false },
      { HealthQuestionID: 3, HealthQuestion: 'Eats/mouths non-edibles after 15 months of age', TF: false },
      { HealthQuestionID: 4, HealthQuestion: 'Poor sleep habits - difficulty falling asleep/staying asleep/routinely falls asleep during engaging activities', TF: false },
      { HealthQuestionID: 5, HealthQuestion: 'Allergies to food/animals/medications/environment', TF: false },
      { HealthQuestionID: 6, HealthQuestion: 'Poor appetite/picky eater/swallows food without chewing/overstuffs mouth', TF: false },
      { HealthQuestionID: 7, HealthQuestion: 'Mouth breathes/drools beyond teething period', TF: false },
    ];
  }

  private getDevelopmentQuestions() {
    this.DevelopmentQuestions = [
      { DevelopmentQuestionID: 1, DevelopmentQuestion: 'Concerned child is behind in one/more areas of their development', TF: false },
      { DevelopmentQuestionID: 2, DevelopmentQuestion: 'Child referred for developmental testing/to Infant Toddler Connection/receiving therapy services', TF: false },
      { DevelopmentQuestionID: 3, DevelopmentQuestion: 'Developmentally above their peers', TF: false },
      { DevelopmentQuestionID: 4, DevelopmentQuestion: 'Stiff,tight muscles/very flexible/floppy like a rag doll/drags or does not use one side of the body', TF: false },
      { DevelopmentQuestionID: 5, DevelopmentQuestion: 'Sensory loss or deficit (hearing/vision, etc.)/loss of limb (arm,leg)', TF: false },
      { DevelopmentQuestionID: 6, DevelopmentQuestion: 'Toddler does not imitate words or actions of others/pays attention to others\'\ words or actions', TF: false },
      { DevelopmentQuestionID: 7, DevelopmentQuestion: 'Does not use gestures such as waving, pointing or head shaking to communicate', TF: false },
      { DevelopmentQuestionID: 8, DevelopmentQuestion: 'Toddles does not seem to know the function of common household object (brush, cellphone, fork, spoon, cup, etc.)', TF: false },
      { DevelopmentQuestionID: 9, DevelopmentQuestion: 'Does not play with toys as expected for age/does not respond to name', TF: false },
      { DevelopmentQuestionID: 10, DevelopmentQuestion: 'Loss or regression of skills or behaviors', TF: false },
    ];
  }

  private getSocialEmotionalQuestions() {
    this.SocialEmotionalQuestions = [
      { SocialEmotionalQuestionID: 1, SocialEmotionalQuestion: 'Delayed/different social-emotional patterns when compared to peers', TF: false },
      { SocialEmotionalQuestionID: 2, SocialEmotionalQuestion: 'Does not express emotions or affection/does not respond appropriately to others\'\ emotions/becomes easily upset/fearful/worries', TF: false },
      { SocialEmotionalQuestionID: 3, SocialEmotionalQuestion: 'Attached to only one person/not attached to any person/extreme difficulty separating', TF: false },
      { SocialEmotionalQuestionID: 4, SocialEmotionalQuestion: 'Does not initiate play/does not explore the environment/cannot play alone for a few minutes', TF: false },
      { SocialEmotionalQuestionID: 5, SocialEmotionalQuestion: 'Rigid with routines/extreme difficulty waiting for something they want/fixated on one object or person', TF: false },
      { SocialEmotionalQuestionID: 6, SocialEmotionalQuestion: 'Toddles does not express their wants and needs verbally.does not regulate their emotions', TF: false },
      { SocialEmotionalQuestionID: 7, SocialEmotionalQuestion: 'Does not use gestures such as waving, pointing, or head shaking to communicate ', TF: false },
      { SocialEmotionalQuestionID: 8, SocialEmotionalQuestion: 'Does not smile/avoids eye contact/ fleeting eye contact', TF: false },
    ];
  }

  private getBehaviorQuestions() {
    this.BehaviorQuestions = [
      { BehaviorQuestionID: 1, BehaviorQuestion: 'Concerns for child\'\s safety/child doesn\'\t recognize danger/does not respond or register pain', TF: false },
      { BehaviorQuestionID: 2, BehaviorQuestion: 'Too passive/refuses to try/easily frustrated/refuses to follow requests', TF: false },
      { BehaviorQuestionID: 3, BehaviorQuestion: 'Tantrums lasting more than 30 minutes/frequent tantrums throughout the day', TF: false },
      { BehaviorQuestionID: 4, BehaviorQuestion: 'Constantly in motion/lethargic/hand flopping/twirling', TF: false },
      { BehaviorQuestionID: 5, BehaviorQuestion: 'Sits very close to others/enjoys rough housing/bumps/runs away', TF: false },
      { BehaviorQuestionID: 6, BehaviorQuestion: 'Frequently call parent to pick the child up early/prefers when child is absent', TF: false },
      { BehaviorQuestionID: 7, BehaviorQuestion: 'Hits/kicks/bites/spits/throws object/falls out', TF: false },
      { BehaviorQuestionID: 8, BehaviorQuestion: 'Self-injurious behaviors/head banging/skin picking/rocking', TF: false },
      { BehaviorQuestionID: 9, BehaviorQuestion: 'Sensitive to or prefers certain sounds, light, or textures', TF: false },
    ];
  }

  private getEngagementQuestions() {
    this.EngagementQuestions = [
      { EngagementQuestionID: 1, EngagementQuestion: 'Toddles is often withdrawn/prefers to be alone/in their world', TF: false },
      { EngagementQuestionID: 2, EngagementQuestion: 'Toddler has difficulty taking turns/playing with others', TF: false },
      { EngagementQuestionID: 3, EngagementQuestion: 'Difficulty maintaining the child\'\s interest in activities/objects/people ', TF: false },
      { EngagementQuestionID: 4, EngagementQuestion: 'Another teacher can handle/address the child\'\s needs better', TF: false },
      { EngagementQuestionID: 5, EngagementQuestion: 'Challenging/difficult to interact with/bond with the child', TF: false },
      { EngagementQuestionID: 6, EngagementQuestion: 'Difficult to acknowledge the child physically/verbally praise the child', TF: false },
      { EngagementQuestionID: 7, EngagementQuestion: 'Toddler struggles with personal space/boundaries', TF: false },
    ];
  }

  private getTraumaQuestions() {
    this.TraumaQuestions = [
      { TraumaQuestionID: 1, TraumaQuestion: 'Household changes in the last 6 months - deployment/incarceration/divorce/separation/hospitalization', TF: false },
      { TraumaQuestionID: 2, TraumaQuestion: 'CPS involvement/foster care', TF: false },
      { TraumaQuestionID: 3, TraumaQuestion: 'Custodial issues/grandparents raising child/other custodians raising child', TF: false },
      { TraumaQuestionID: 4, TraumaQuestion: 'Family experienced adversities/hardships in the last 6 months (death, pet death, disability, etc.)', TF: false },
      { TraumaQuestionID: 5, TraumaQuestion: 'Known history of trauma/abuse/neglect', TF: false },
    ];
  }

  private getEnvironmentalCulturalQuestions() {
    this.EnvironmentalCulturalQuestions = [
      { EnvironmentalCulturalQuestionID: 1, EnvironmentalCulturalQuestion: 'Bilingual/English as a second language/deaf household', TF: false },
      { EnvironmentalCulturalQuestionID: 2, EnvironmentalCulturalQuestion: 'Concerns for neighborhood safety/gang violence concerns', TF: false },
      { EnvironmentalCulturalQuestionID: 3, EnvironmentalCulturalQuestion: 'Family lacks transportation/access to grocery store or other community resources', TF: false },
      { EnvironmentalCulturalQuestionID: 4, EnvironmentalCulturalQuestion: 'Family experienced a natural disaster/fire/accident/burglary', TF: false },
      { EnvironmentalCulturalQuestionID: 5, EnvironmentalCulturalQuestion: 'Family has housing instability/lives with others/resides in a shelter', TF: false },
      { EnvironmentalCulturalQuestionID: 6, EnvironmentalCulturalQuestion: 'Family receives child care subsidy', TF: false },
      { EnvironmentalCulturalQuestionID: 7, EnvironmentalCulturalQuestion: 'Family is isolated/no community involvement', TF: false },
    ];
  }

  private initForm() {
    this.ChildSpecificScaleForm = new FormGroup(
      {
        User: new FormControl(null, [Validators.required] ),
        DateSubmitted: new FormControl({ value: new Date(), disabled: true }),
        ReferralName: new FormControl('', [Validators.required]),
        ChildId: new FormControl(null, [Validators.required]),
        AgeMonth: new FormControl('', [Validators.required]),
        HealthQuestion1: new FormControl(false),
        HealthQuestion2: new FormControl(false),
        HealthQuestion3: new FormControl(false),
        HealthQuestion4: new FormControl(false),
        HealthQuestion5: new FormControl(false),
        HealthQuestion6: new FormControl(false),
        HealthQuestion7: new FormControl(false),
        DevelopmentQuestion1: new FormControl(false),
        DevelopmentQuestion2: new FormControl(false),
        DevelopmentQuestion3: new FormControl(false),
        DevelopmentQuestion4: new FormControl(false),
        DevelopmentQuestion5: new FormControl(false),
        DevelopmentQuestion6: new FormControl(false),
        DevelopmentQuestion7: new FormControl(false),
        DevelopmentQuestion8: new FormControl(false),
        DevelopmentQuestion9: new FormControl(false),
        DevelopmentQuestion10: new FormControl(false),
        SocialEmotionalQuestion1: new FormControl(false),
        SocialEmotionalQuestion2: new FormControl(false),
        SocialEmotionalQuestion3: new FormControl(false),
        SocialEmotionalQuestion4: new FormControl(false),
        SocialEmotionalQuestion5: new FormControl(false),
        SocialEmotionalQuestion6: new FormControl(false),
        SocialEmotionalQuestion7: new FormControl(false),
        SocialEmotionalQuestion8: new FormControl(false),
        BehaviorQuestion1: new FormControl(false),
        BehaviorQuestion2: new FormControl(false),
        BehaviorQuestion3: new FormControl(false),
        BehaviorQuestion4: new FormControl(false),
        BehaviorQuestion5: new FormControl(false),
        BehaviorQuestion6: new FormControl(false),
        BehaviorQuestion7: new FormControl(false),
        BehaviorQuestion8: new FormControl(false),
        BehaviorQuestion9: new FormControl(false),
        EngagementQuestion1: new FormControl(false),
        EngagementQuestion2: new FormControl(false),
        EngagementQuestion3: new FormControl(false),
        EngagementQuestion4: new FormControl(false),
        EngagementQuestion5: new FormControl(false),
        EngagementQuestion6: new FormControl(false),
        EngagementQuestion7: new FormControl(false),
        TraumaQuestion1: new FormControl(false),
        TraumaQuestion2: new FormControl(false),
        TraumaQuestion3: new FormControl(false),
        TraumaQuestion4: new FormControl(false),
        TraumaQuestion5: new FormControl(false),
        EnvironmentalCulturalQuestion1: new FormControl(false),
        EnvironmentalCulturalQuestion2: new FormControl(false),
        EnvironmentalCulturalQuestion3: new FormControl(false),
        EnvironmentalCulturalQuestion4: new FormControl(false),
        EnvironmentalCulturalQuestion5: new FormControl(false),
        EnvironmentalCulturalQuestion6: new FormControl(false),
        EnvironmentalCulturalQuestion7: new FormControl(false),
      }
    );
  }

  public isInputValid = (formControlName: string): boolean => (!this.ChildSpecificScaleForm.get(formControlName)?.valid && (this.ChildSpecificScaleForm.get(formControlName).dirty || (this.ChildSpecificScaleForm.get(formControlName).touched)));

  onSubmit() {
    this.ChildSpecificScaleForm.markAllAsTouched();
    if (!this.ChildSpecificScaleForm.valid) {
      console.warn("error happened: ", this.ChildSpecificScaleForm.value);
    } else {
      this.finalizeChildSpecificScaleFormData();
      this.childSpecificScaleService.post(this.ChildSpecificScaleSubmitForm)
      .subscribe({
        next: (n) => {
          this.toast.success("Form submitted successfully.")
          this.router.navigate(['app/dashboard']);
        }
      });
    }
  }

  finalizeChildSpecificScaleFormData(){
    this.ChildSpecificScaleForm.patchValue({ User: this.currentVAUser.id });
    this.ChildSpecificScaleSubmitForm = {
      VAUserId: this.ChildSpecificScaleForm.controls['User'].value,
      ReferralName: this.ChildSpecificScaleForm.controls['ReferralName'].value,
      ChildId: this.ChildSpecificScaleForm.controls['ChildId'].value,
      AgeMonth: this.ChildSpecificScaleForm.controls['AgeMonth'].value,
      IsHealthPremature: this.ChildSpecificScaleForm.controls['HealthQuestion1'].value,
      IsHealthPreMeds: this.ChildSpecificScaleForm.controls['HealthQuestion2'].value,
      IsHealthEats: this.ChildSpecificScaleForm.controls['HealthQuestion3'].value,
      IsHealthPoorSleep: this.ChildSpecificScaleForm.controls['HealthQuestion4'].value,
      IsHealthAllergic: this.ChildSpecificScaleForm.controls['HealthQuestion5'].value,
      IsHealthPoorAppetite: this.ChildSpecificScaleForm.controls['HealthQuestion6'].value,
      IsHealthMouthBreaths: this.ChildSpecificScaleForm.controls['HealthQuestion7'].value,
      IsDevelopBehind: this.ChildSpecificScaleForm.controls['DevelopmentQuestion1'].value,
      IsDevelopChildReferred: this.ChildSpecificScaleForm.controls['DevelopmentQuestion2'].value,
      IsDevelopAbovePeers: this.ChildSpecificScaleForm.controls['DevelopmentQuestion3'].value,
      IsDevelopStiff: this.ChildSpecificScaleForm.controls['DevelopmentQuestion4'].value,
      IsDevelopSensoryLoss: this.ChildSpecificScaleForm.controls['DevelopmentQuestion5'].value,
      IsDevelopNoImitation: this.ChildSpecificScaleForm.controls['DevelopmentQuestion6'].value,
      IsDevelopNoGestures: this.ChildSpecificScaleForm.controls['DevelopmentQuestion7'].value,
      IsDevelopKnowHouseholdObj: this.ChildSpecificScaleForm.controls['DevelopmentQuestion8'].value,
      IsDevelopNoToyPlay: this.ChildSpecificScaleForm.controls['DevelopmentQuestion9'].value,
      IsDevelopRegression: this.ChildSpecificScaleForm.controls['DevelopmentQuestion10'].value,
      IsSocialEmotionalDelayed: this.ChildSpecificScaleForm.controls['SocialEmotionalQuestion1'].value,
      IsSocialEmotionalNotExpressional: this.ChildSpecificScaleForm.controls['SocialEmotionalQuestion2'].value,
      IsSocialEmotionalSingleAttachment: this.ChildSpecificScaleForm.controls['SocialEmotionalQuestion3'].value,
      IsSocialEmotionalNoInitiatePlay: this.ChildSpecificScaleForm.controls['SocialEmotionalQuestion4'].value,
      IsSocialEmotionalRigid: this.ChildSpecificScaleForm.controls['SocialEmotionalQuestion5'].value,
      IsSocialEmotionalNoDisplayNeeds: this.ChildSpecificScaleForm.controls['SocialEmotionalQuestion6'].value,
      IsSocialEmotionalNoGestures: this.ChildSpecificScaleForm.controls['SocialEmotionalQuestion7'].value,
      IsSocialEmotionalNoSmile: this.ChildSpecificScaleForm.controls['SocialEmotionalQuestion8'].value,
      IsBehaviorConcernChildSafety: this.ChildSpecificScaleForm.controls['BehaviorQuestion1'].value,
      IsBehaviorTooPassive: this.ChildSpecificScaleForm.controls['BehaviorQuestion2'].value,
      IsBehaviorLongTantrums: this.ChildSpecificScaleForm.controls['BehaviorQuestion3'].value,
      IsBehaviorConstantlyInMotion: this.ChildSpecificScaleForm.controls['BehaviorQuestion4'].value,
      IsBehaviorSitsClose: this.ChildSpecificScaleForm.controls['BehaviorQuestion5'].value,
      IsBehaviorChildAbsent: this.ChildSpecificScaleForm.controls['BehaviorQuestion6'].value,
      IsBehaviorAggressive: this.ChildSpecificScaleForm.controls['BehaviorQuestion7'].value,
      IsBehaviorSelfInjury: this.ChildSpecificScaleForm.controls['BehaviorQuestion8'].value,
      IsBehaviorSensitive: this.ChildSpecificScaleForm.controls['BehaviorQuestion9'].value,
      IsEngagementWithdrawn: this.ChildSpecificScaleForm.controls['EngagementQuestion1'].value,
      IsEngagementDiffTurnTaking: this.ChildSpecificScaleForm.controls['EngagementQuestion2'].value,
      IsEngagementDiffInterest: this.ChildSpecificScaleForm.controls['EngagementQuestion3'].value,
      IsEngagementOtherTeacher: this.ChildSpecificScaleForm.controls['EngagementQuestion4'].value,
      IsEngagementChallenging: this.ChildSpecificScaleForm.controls['EngagementQuestion5'].value,
      IsEngagementDiffAcknowledge: this.ChildSpecificScaleForm.controls['EngagementQuestion6'].value,
      IsEngagementBoundaries: this.ChildSpecificScaleForm.controls['EngagementQuestion7'].value,
      IsTraumaHouseholdChange: this.ChildSpecificScaleForm.controls['TraumaQuestion1'].value,
      IsTraumaCPS: this.ChildSpecificScaleForm.controls['TraumaQuestion2'].value,
      IsTraumaCustodial: this.ChildSpecificScaleForm.controls['TraumaQuestion3'].value,
      IsTraumaFamily: this.ChildSpecificScaleForm.controls['TraumaQuestion4'].value,
      IsTraumaHistory: this.ChildSpecificScaleForm.controls['TraumaQuestion5'].value,
      IsEnvironmentalBilingual: this.ChildSpecificScaleForm.controls['EnvironmentalCulturalQuestion1'].value,
      IsEnvironmentalConcerns: this.ChildSpecificScaleForm.controls['EnvironmentalCulturalQuestion2'].value,
      IsEnvironmentalLacksTransportation: this.ChildSpecificScaleForm.controls['EnvironmentalCulturalQuestion3'].value,
      IsEnvironmentalExpNaturalDisaster: this.ChildSpecificScaleForm.controls['EnvironmentalCulturalQuestion4'].value,
      IsEnvironmentalHouseInstability: this.ChildSpecificScaleForm.controls['EnvironmentalCulturalQuestion5'].value,
      IsEnvironmentalChildCare: this.ChildSpecificScaleForm.controls['EnvironmentalCulturalQuestion6'].value,
      IsEnvironmentalIsolated: this.ChildSpecificScaleForm.controls['EnvironmentalCulturalQuestion7'].value,
      Created: this.ChildSpecificScaleForm.controls['DateSubmitted'].value,
      CreatedByUser: 'null',
      LastUpdatedUser: 'null',
    };
  }

  ngOnDestroy() {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }

}
